/* ==========================================================================
admin.scss - affichage connecté pour un admin/superadmin
========================================================================== */


/**
* Drupal Tabs
*/
.tabs.primary {
  @include menu-base;
  @include menu-direction(horizontal);
  @include flex-grid-row($size: #{rem-calc(1000)});
  border: none;
  background-color: #fff;
  margin-bottom: 5vh;
  box-shadow: inset rem-calc(0 -1 0 0) $color-divider;
  position: relative;
  z-index: 1;

  & > li {
    //border-right: 1px solid $color-divider;
  }

  & > li > a {
    font-size: rem-calc(18);
    font-weight: 500;
    color: $color-main;
    transition: color .2s, background-color .2s;

    &.is-active {
      //color: #fff;
      box-shadow: inset rem-calc(0 -2 0 0) $color-main;
    }

    &:hover,
    &:focus {
      color: $color-main;
      background-color: scale-color($color-divider, $lightness : 5%);
    }
  }

}
/**
* User Layout
*/
#user-login,
#user-pass,
.profile{
  @include flex-grid-row;
  @include flex-align(center, top);

  &>div{
    @include flex-grid-column(6);
  }
  &>h3,
  &>dl{
    @include flex-grid-row($size : #{rem-calc(800)});
    width: 100%;
  }
  dt{
    @include flex-grid-column(4, 0);
  }
  dd{
    @include flex-grid-column(8, 0);
  }
}


/**
* Drupal Messages
* Status / Warning / Error
*/
.messages{
  @include flex-grid-row($size: #{rem-calc(800)});
  @include flex-align(center, middle);
  width: 100%;
  padding: rem-calc(15 20 10);
  margin-top: rem-calc(10);
  margin-bottom: rem-calc(30);
  font-weight: 500;
  text-align: center;

  @include breakpoint(large) {
    margin-bottom: rem-calc(40);
    padding: rem-calc(30 40 25);
  }

  &.error{
    background-color: scale-color(#e63247, $lightness : 92%);
    border: solid rem-calc(1) scale-color(#e63247, $lightness : 75%);
  }
  &.status{
    background-color: $color-paleblue;
    border: solid rem-calc(1) scale-color($color-paleblue, $lightness : -10%);
  }
  &.warning{
    background-color: scale-color(#ECAF3F, $lightness : 92%);
    border: solid rem-calc(1) scale-color(#ECAF3F, $lightness : 75%);
  }

  a{
    text-decoration: underline;
    padding: rem-calc(0 5);
  }

  // Variations if messages are shown inside a form
  form &{
    margin-top: 0;
    margin-bottom: rem-calc(10);
    animation: messagesIn .4s $easeOutExpo both;

    @include breakpoint(large) {
      margin-bottom: rem-calc(20);
    }
  }
}

/**
* Shortcuts admin bar Fix
*/
.toolbar-shortcuts .menu{
  @include menu-direction(vertical);
  width: auto;
  max-width: rem-calc(160);
}
