/**
* Detail
* Detail page (artiste / auteur / news / Agenda)
*/

.detail{
  position: relative;
  z-index: 1;

  @include breakpoint(large) {
    margin-bottom: rem-calc(50);
  }

  &__container{
    @include flex-grid-row;
    @include flex-align(center, top);
  }

  &__content{
    @include flex-grid-column(11, 0);
    @include flex-grid-row;

    @include breakpoint(large) {
      @include flex-grid-size(12);
      align-items: stretch;
      margin-top: rem-calc(20);
      min-height: rem-calc(450);
      position: relative;
    }
  }

  &__desc{
    @include flex-grid-column(12, 0);
    margin-bottom: rem-calc(30);

    @include breakpoint(large) {
      @include flex-grid-size(8);
      padding: rem-calc(0 240 40 70);
      position: relative;

      // Frame
      &::after{
        content: '';
        position: absolute;
        z-index: -1;
        top: rem-calc(30);
        left: 0;
        width: 100%;
        height: calc(100% - #{rem-calc(30)});
        box-shadow: inset rem-calc(0 0 0 2) $color-divider;
      }
    }
  }

  &__image{
    @include flex-grid-column(12, 0);
    @include flex-grid-row();
    @include flex-align(center, top);

    .img-detail{
      box-shadow: rem-calc(0 20 50) rgba($color-dark, .25);
    }

    @include breakpoint(large) {
      @include flex-grid-size(6);
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;

      .img-detail{
        position: relative;
        z-index: 1;
      }
    }
  }

  &__title {
    display: inline;
    font-family: $font-main;
    font-size: rem-calc(46);
    font-weight: 700;
    line-height: 1.05;
    color: $color-dark;
    letter-spacing: -0.01em;

    span {
      // word-wrap: break-word;
      // word-break: break-all;
      overflow-wrap: break-word;
      // hyphens: auto;
    }

    @include breakpoint(large) {
      position: relative;
      z-index: 1;
      margin: rem-calc(0 -17 30);
      font-size: rem-calc(60);

      span{
        position: relative;
        background-color: #fff;
        padding: rem-calc(0 15);
      }
    }


  }

  .spec{
    margin-top: rem-calc(30);
    position: relative;

    & + .spec{
      margin-top: rem-calc(5);

      @include breakpoint(large) {
        margin-top: rem-calc(15);
      }
    }

    & > span{
      position: absolute;
      top: 0;
      left: 0;
      text-transform: uppercase;
      font-size: rem-calc(13);
      font-weight: 500;
      letter-spacing: .1em;
      color: rgba($color-dark, .4);

      &::after{
        content: '';
        position: absolute;
        top: 50%;
        left: calc(100% + #{rem-calc(10)});
        width: rem-calc(30);
        height: rem-calc(2);
        background-color: $color-divider;

        @include breakpoint(large) {
          width: rem-calc(50);
        }
      }
      // plusieurs dates
      &.multiple::after{
        width: rem-calc(20);

        @include breakpoint(large) {
          width: rem-calc(38);
        }
      }
    }
    .field-date,
    .field-lieu{
      padding-left: rem-calc(85);
      font-weight: 500;

      @include breakpoint(large) {
        padding-left: rem-calc(105);
      }

      button{
        display: inline-block;
        font-family: $font-main;
        font-size: rem-calc(16);
        font-weight: 500;
        color: scale-color($color-main, $lightness: -10%);
        position: relative;
        padding: rem-calc(2 0);
        border-bottom: rem-calc(2) solid $color-main;
      }
    }

    .date-display-single span{
      margin-left: rem-calc(5);
      color: scale-color($color-main, $lightness: -10%);
    }
  }

  .field-body{
    margin-top: rem-calc(20);
    color: rgba($color-dark, .7);
    line-height: 1.6;
    overflow: hidden; // Prevent client adding 1000x &nbsp; 😤

    p{
      line-height: inherit;
    }

    @include breakpoint(large) {
      margin-top: rem-calc(35);
      line-height: 1.7;
    }
  }

  .tag{
    color: $color-dark;
    font-weight: 500;
    font-size: rem-calc(17);
    margin: rem-calc(5 0 0);
  }
}
