/**
* Blocs
* Displayed everywhere on the site.
* Including:
* - Title
* - Featured
* - Agenda
* - Map
* - Videos
*/
.bloc{
  position: relative;
  overflow: hidden; // For data-title
  width: 100%; // For data-title

  /**
  * Generic styles
  */
  &__container{
    @include flex-grid-row();
    @include flex-align(center, top);
  }

  &__content{
    @include flex-grid-column(12);
    padding-top: rem-calc(40);
    padding-bottom: rem-calc(40);

    @include breakpoint(large) {
      // @include flex-grid-size(12);
      padding-top: rem-calc(80);
      padding-bottom: rem-calc(80);
    }
  }

  &__heading{
    // @include flex-grid-column(12);
    position: relative;

    // Link to full page
    &__link {
      text-align: center;
      font-weight: 600;
      font-size: rem-calc(16);
      transform: translateY(-100%);

      @include breakpoint(large) {
        position: absolute;
        top: 50%;
        right: rem-calc(15);
        transform: translateY(-50%);
      }

      a {
        position: relative;
        padding-right: rem-calc(25);

        &::after{
          @include glyph;
          content: '\62';
          position: absolute;
          top: rem-calc(-2);
          right: 0;
          font-size: rem-calc(20);
        }
      }
    }
  }

  &__title {
    text-align: center;
    font-size: rem-calc(38);
    // font-weight: 800;
    // color: $color-main;
    

    @include breakpoint(large) {
      font-size: rem-calc(55);
    }

    &[data-title] {
      margin-top: rem-calc(20);
      padding-top: rem-calc(30);
      margin-bottom: rem-calc(20);
      padding-bottom: rem-calc(20);
      @extend .multiline-bg;
      // line-height: 1.05;

      &::after {
        display: none; // Hide decoration We dont need it on ZAT 2020
        content: attr(data-title);
        font-size: 20vw;
        font-weight: 800;
        color: $color-light;
        position: absolute;
        left: 50%;
        // z-index: -1;
        margin-top: -0.4em;
        white-space: nowrap;
        transform: translateX(-50%);

        @include breakpoint(large) {
          font-size: rem-calc(120);
          margin-top: -0.45em;
        }
      }

      span{
        position: relative;
        z-index: 1;
      }
    }
  }

  &__inner{
    @include flex-grid-column(12, 0);
  }

  &__description{
    @include flex-grid-column(12, 0);

    @include breakpoint(large) {
      @include flex-grid-size(3);
      margin-top: rem-calc(20);
    }
  }

  &__body{
    @include flex-grid-column(12, 0);

    @include breakpoint(large) {
      @include flex-grid-size(9);
    }
  }

  &__bg{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    backface-visibility: hidden;
  }
  &__bg + &__container{
    position: relative;
  }


  /**
  * Generic styles title left
  */
  &--left &__title{
    text-align: left;

    &[data-title]{

      &::after{
        left: 0;
        transform: none;
      }
    }
  }
  &--left &__inner{
    @include flex-grid-row;
  }
  &--left &__description{
    color: rgba($color-dark, .7);
    font-size: rem-calc(17);

    p{
      color: inherit;
      font-size: inherit;
    }

    @include breakpoint(large) {
      margin-top: rem-calc(40);
      padding-right: rem-calc(60);
    }
  }

  /**
  * Generic styles left (2 cols)
  */
  @at-root .node-galerie-multimedia &--titleleft{
    z-index: 1;
  }
  @at-root .detail + &--titleleft{
    z-index: 1;
  }
  &--titleleft &__title{
    text-align: left;

    &[data-title]{
      padding-bottom: 0;

      @include breakpoint(large) {
        margin-right: rem-calc(-20);
      }

      &::after{
        left: 0;
        transform: none;
      }
    }
  }
  &--titleleft &__inner{
    @include flex-grid-row;
    @include flex-align(right, top);
  }
  &--titleleft &__content{
    padding-top: 0;
    position: relative;
  }
  &--titleleft &__body{
    position: relative;
  }
  &--titleleft &__heading{

    @include breakpoint(large) {
      @include flex-grid-size(3);
      position: absolute;
      left: 0;
      width: 100%;
    }
  }

  /**
  * Generic Big Title (galerie archive)
  */
  &--bigtitle &__title{

    &[data-title]{
      font-size: rem-calc(60);
      margin: 0;
      padding: 0;
      color: #e6ebed;
      font-weight: normal;

      @include breakpoint(large) {
        font-size: rem-calc(120);
      }

      &::after{
        display: none;
      }
    }
  }
  &--bigtitle &__inner{
    margin-top: rem-calc(-20);
    position: relative;
    z-index: 1;

    @include breakpoint(large) {
      margin-top: rem-calc(-40);
    }
  }
  &--bigtitle &__content{
    padding-top: 0;
  }

  /**
  * Generic No Title (galerie)
  */
  &--notitle &__content{
    padding-top: 0;
  }


  /**
  * Generic styles Title Right
  */
  &--right &__title{
    text-align: right;

    &[data-title]{

      &::after{
        left: auto;
        right: 0;
        transform: none;
      }
    }
  }
  &--right &__inner{
    @include flex-grid-row;
  }
  &--right &__description{
    color: rgba(#FFFFFF, .8);
    font-size: rem-calc(17);

    p{
      color: inherit;
      font-size: inherit;
    }

    @include breakpoint(large) {
      margin-top: rem-calc(40);
      padding-left: rem-calc(60);
    }
  }



  /**
  * Bloc Accordion
  */

  &--accordion &__content{
    padding-top: 0;

    @include breakpoint(large) {
      @include flex-grid-size(70%);
    }
  }



  /**
  * Bloc Hero
  */
  &--hero {
    background-color: $color-alt;
    overflow: hidden;
  }
  &--hero &__bg{
    background-image: url(../img/zat_hero_home-2020.jpg);

    @include breakpoint(small only) {
      background-position: 63% 0%;
      height: 140%;
      opacity: .8;
    }
  }
  &--hero &__content{
    @include flex-grid-column(12);
    color: #fff;
    padding-bottom: rem-calc(80);

    a{ color: #fff; }

    @include breakpoint(large) {
      @include flex-grid-row();
      justify-content: space-between;
      padding-top: rem-calc(120);
      padding-bottom: rem-calc(200);
    }
  }

  &--hero &__heading{
    @include flex-grid-column(12, 0);

    @include breakpoint(large) {
      @include flex-grid-size(6);
    }
  }

  &--hero &__title {
    // @include text-shadow-dark($color: #000000);
    text-align: left;
    font-size: rem-calc(30);
    // line-height: 1;
    margin-bottom: rem-calc(20);
    @extend .multiline-bg;

    br {
      @include hide-for-only(small);
    }

    @include breakpoint(large) {
      font-size: rem-calc(48);
      margin-bottom: rem-calc(30);
    }
  }
  &--hero &__subtitle{
    // @include text-shadow-dark($color: darken(#000000, 5%));
    font-weight: 700;
    font-size: rem-calc(20);
    margin-bottom: rem-calc(20);
    // padding-left: rem-calc(66);
    position: relative;
    @extend .multiline-bg;

    // &::before{
    //   display: none;
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   top: 50%;
    //   margin-top: -.05em;
    //   width: rem-calc(56);
    //   height: rem-calc(3);
    //   background-color: $color-main;

    //   @include breakpoint(large) {
    //     background-color: #ffffff;
    //   }
    // }

    @include breakpoint(large) {
      font-size: rem-calc(22);
      margin-bottom: 0;
      // padding-left: rem-calc(100);
      // color: #ffffff;
    }
  }

  &--hero &__inner{
    @include flex-grid-column(12, 0);
    color: $color-alt;

    @include breakpoint(large) {
      @include flex-grid-size(5);
      //@include grid-column-offset(1);
    }

    p {
      // @include text-shadow-dark($color: darken(#000000, 5%));
      font-size: rem-calc(20);
      color: $color-main;
      font-weight: 700;
    }

    a {
      @include filet-link;
      color: $color-main;
    }
  }

  &--hero &__description{
    @include flex-grid-size(12);

    // @include breakpoint(large) {
    //   @include flex-grid-size(11);
    // }

  }

  &--hero &__link{
    text-align: right;

    a {
      @include filet-link;
    }

    // @include breakpoint(large) {
    //   @include flex-grid-size(11);
    // }
  }



  /**
  * Bloc Featured
  */
  &--featured{
    @include bloc-skew($color-light);
    overflow: visible;
  }
  &--featured &__content{
    padding-top: 0;
    margin-top: rem-calc(-60);

    @include breakpoint(large) {
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
      margin-top: rem-calc(-120);
    }
  }
  &--featured &__title{
    font-size: 12vw;
    line-height: .9;
    font-weight: 800;
    color: $color-alt;
    opacity: 0.4;
    width: 48%;
    position: absolute;
    top: 0;
    left: 0;
    text-align: right;
    margin-top: rem-calc(50);
    margin-bottom: rem-calc(20);

    @include breakpoint(medium) {
      width: 31%;
      font-size: rem-calc(52);
      margin-top: rem-calc(120);
    }

    @include breakpoint(xlarge) {
      width: 28%;
      margin-left: -4vw;
      font-size: rem-calc(65);
      margin-top: rem-calc(220);
      margin-bottom: 0;
    }

    span{
      display: inline-block;

      @include breakpoint(large) {
        position: relative;
        top: 0;
        right: 0;
        width: 110%;
        height: 100%;
        transform: translate(rem-calc(5), rem-calc(20));
      }
    }
  }


  /**
  * Bloc Agenda
  */
  &--agenda &__content{
    padding-bottom: 0;
  }


  /**
  * Bloc Map
  */
  &--map{
    position: relative;
    z-index: 1;
  }
  &--map #map-canvas{
    box-shadow: rem-calc(0 20 50) rgba($color-dark, .20);

    @include breakpoint(large) {
      box-shadow: rem-calc(0 25 60) rgba($color-dark, .25);
    }
  }
  &--map &__title{

    &[data-title]{
      margin-bottom: rem-calc(10);
      padding-bottom: rem-calc(10);

      @include breakpoint(large) {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  // Page Agenda Map
  $map-offset-small : rem-calc(165);
  $map-offset-large : rem-calc(330);

  &--agendamap{
    @include bloc-skew($color-alt);
    margin-top: $map-offset-small;
    overflow: visible !important;

    @include breakpoint(large) {
      margin-top: $map-offset-large;
    }
  }
  &--agendamap &__content{
    padding-top: 0;
    margin-top: -$map-offset-small;

    @include breakpoint(large) {
      @include flex-grid-size(11);
      margin-top: -$map-offset-large;
    }
  }
  &--agendamap .gmap-map{
    box-shadow: rem-calc(0 20 50) rgba($color-dark, .20);

    @include breakpoint(large) {
      box-shadow: rem-calc(0 25 60) rgba($color-dark, .25);
    }
  }


  /**
  * Bloc Multimedia (point de vie point de vue)
  */
  &--multimedia{
    @include bloc-skew($color-main);
    margin-top: rem-calc(-120);

    @include breakpoint(large) {
      margin-top: rem-calc(-240);
    }

    a{
      color: $color-main;
      text-decoration: underline;
    }
    a:hover{
      color: #fff;
    }
  }
  &--multimedia &__title{
    color: #fff;

    &[data-title]::after{
      color: inherit;
      opacity: .13;
    }
  }


  /**
  * Bloc Page Title
  */
  &--pagetitle &__bg {
    background-image: url(../img/zat_hero_pages-2020.jpg);
    background-position: 50% 0%;
    background-color: $color-alt;
    height: 50%;
    top: 0;
    transform: none;

    @include breakpoint(large) {
      height: 70%;
    }
  }
  &--pagetitle &__container{
    @include bloc-skew(#fff);
    max-width: 100%;
    margin-top: rem-calc(50);

    @include breakpoint(large) {
      margin-top: rem-calc(120);
    }
  }
  &--pagetitle &__content{
    padding: 0;
  }
  &--pagetitle &__title{
    margin-top: 0;
    // ZAT 2020 Cutom
    margin-top: rem-calc(-60);
    margin-bottom: rem-calc(40);

    @include breakpoint(medium) {
      margin-top: rem-calc(-90);
      margin-bottom: rem-calc(80);
    }
    // END ZAT 2020 Cutom

    &::after{
      margin-top: -0.3em;

      @include breakpoint(large) {
        margin-top: -0.45em;
      }
    }
  }


  /**
  * Bloc Page Title
  */
  &--related{
    @include bloc-skew(#f5f5f5);
    margin-top: -5vw;

    @include breakpoint(large) {
      margin-top: 0;
    }
  }
  // Variation when related is immediatly after a `titleleft` bloc
  &--titleleft + &--related{
    margin-top: rem-calc(-80);

    @include breakpoint(large) {
      margin-top: rem-calc(-150);
    }
  }

  &--related &__content{

    @include breakpoint(large) {
      padding-top: 0;
    }
  }
  &--related &__title{
    text-align: right;
    font-size: rem-calc(60);
    color: #ededed;
    line-height: 1;
    font-weight: normal;

    @include breakpoint(large) {
      margin-top: 0;
      font-size: rem-calc(100);
    }
  }
  &--related &__inner{
    margin-top: rem-calc(-15);

    @include breakpoint(large) {
      @include flex-grid-size(9);
      margin-top: rem-calc(-30);
      margin-left: auto;
      margin-right: auto;
    }
  }

}
