/**
* Agenda
* Page layout
* Agenda component to display entries is defined in `components/_agenda.scss`
*/

// Main layout
.view-id-agenda:not(.view-display-id-block_gmap){
  @include flex-grid-row;
  @include flex-align(center, top);

  & > .view-content{
    @include flex-grid-column(11, 0);
  }

}
