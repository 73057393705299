/* ==========================================================================
  table.scss
========================================================================== */



// Responsive table
.table-container {
  @media (max-width: 767px) {
    width: 100%;
    overflow: hidden;
    overflow-x: scroll;
  }
}

table {
  position: relative;
  width: 100% !important;
  border: 1px solid #eee;
  border-bottom: none;

  @media (max-width: 767px) {
    width: rem-calc(980) !important;
  }

  @media (max-width: 480px) {
    width: rem-calc(767) !important;
  }

  th {
    background-color: #f4f4f4;
    border-bottom-color: #eee;
    font-weight: 700;
  }

  td, th {
    padding: rem-calc(15 30);
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    line-height: rem-calc(22);

    &:last-child {
      border-right: none;
    }

    @media (max-width: 767px) {
      font-size: rem-calc(14);
      line-height: rem-calc(18);
    }
  }

  tr {
    &:last-child td {
      border-bottom: none;
    }
  }
}
