/**
 * Sitemap
 */

#site-map{
  @include flex-grid-row;
  @include flex-align(center, top);
  @include flex-grid-column(11, 0);

  .site-map-front-page{
    display: none;
  }
}
