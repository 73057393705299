/* ==========================================================================
footer.scss
========================================================================== */

.footer{

  &__bloc{

    /**
    * Generic styles
    */
    &__container{
      @include flex-grid-row();
      @include flex-align(center, top);
    }

    &__content{
      @include flex-grid-column(12);
    }

    /**
    * Variations
    */
    // ### Partners
    &.bloc--partners{
      padding: rem-calc(30 0);

      @include breakpoint(large) {
        padding: rem-calc(80 0 0);
      }

      // Swiper Wrapper transition

      // Swiper Slides
      .swiper-slide{
        text-align: center;
      }
    }
    &.bloc--partners &__content{
      @include breakpoint(large) {
        @include flex-grid-column(10);
      }
    }



    // ### Partners
    &.bloc--newsletter{
      position: relative;
      z-index: 1;
      transform: translateY(50%);
      margin-top: rem-calc(-100);

      @include breakpoint(large) {
        margin-top: rem-calc(-30);
      }
    }
    &.bloc--newsletter &__content{
      @include flex-grid-column(11, 0);

      @include breakpoint(large) {
        @include flex-grid-column(8, 0);
      }
    }



    // ### Nav menus
    &.bloc--nav{
      background-color: $color-light;
      padding: rem-calc(160 0 20);

      @include breakpoint(large) {
        padding-top: rem-calc(220);
      }

      // All menus
      .menu{
        @include menu-direction(vertical);

        @include breakpoint(large) {
          @include menu-direction(horizontal);
        }
      }

      // Footer main nav
      .menu-block-2{
        .menu>li{
          position: relative;

          @include breakpoint(large) {
            margin-right: rem-calc(20);
          }

          &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: rem-calc(10);
            background-color: $color-darkgreen;
            opacity: .2;
            transform: translateX(-50%) scaleY(0);
            transform-origin: 50% 100%;
            transition: transform .3s $easeOutQuart, opacity .2s;
          }
          &:hover::before{
            transform: translateX(-50%) scaleY(1);
          }
        }
        .menu>li.is-active::before{
          transform: translateX(-50%) scaleX(1);
        }
        .menu>li.is-active:hover::before{
          transform: translateX(-50%) translateY(120%) scaleX(1) scaleY(.25);
        }
        .menu>li>a{
          position: relative;
          padding: rem-calc(11 10);
          z-index: 1;
          font-weight: normal;
          font-size: rem-calc(17);
          color: $color-dark;
        }
        .menu>li.is-active>a{
          background: none;
        }
      }

      // Boring nav
      .menu-block-3{

        .menu>li>a{
          padding: rem-calc(5 10);
          font-weight: normal;
          font-size: rem-calc(14);
          color: $color-dark;
          opacity: .4;
          transition: color .3s;

          &:hover{
            opacity: 1;
          }
        }
        .menu>li.is-active>a{
          background: none;
        }
      }
    }
  }

  &__nav{
    @include flex-grid-row;

    &__menu{
      @include flex-grid-column(12);

      @include breakpoint(large) {
        @include flex-grid-column(10);
      }
    }
    &__social{
      @include flex-grid-column(12);
      margin: rem-calc(20 0);

      @include breakpoint(large) {
        @include flex-grid-column(2);
        margin: 0;
      }

      ul{
        justify-content: center;

        @include breakpoint(large) {
          justify-content: flex-end;
        }
      }
    }
  }


  &__mentions{
    @include flex-grid-row;
    margin-top: rem-calc(20);
    padding-top: rem-calc(20);
    border-top: rem-calc(1) solid rgba(#d4d4d4, .5);

    &__nav{
      @include flex-grid-column(12);

      @include breakpoint(large) {
        @include flex-grid-column(10);
      }
    }
    &__credits{
      @include flex-grid-column(12);
      text-align: center;

      @include breakpoint(large) {
        @include flex-grid-column(2);
        text-align: right;
      }
    }
  }

  a.logo_choosit{
    display: inline-block;
    width: rem-calc(60);
		height: rem-calc(16);
		padding-left: rem-calc(60);
		line-height: rem-calc(60);
		overflow: hidden;
		position: relative;
		background: transparent url(../img/logo_choosit.png) 0 0 no-repeat;
		background-size: rem-calc(60 32);

		&::before{
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 0;
			height: 100%;
			background: transparent url(../img/logo_choosit.png) rem-calc(0 -16) no-repeat;
			background-size: rem-calc(60 32);
			transition: width .1s ease-out;
		}
		&:hover::before{
			width: 100%;
		}
  }
}


/**
* Newsletter
*/
.newsletter {
  @include flex-grid-row;
  @include flex-align(center, middle);
  background-color: #fff;
  padding: rem-calc(25 15);
  box-shadow: rem-calc(0 20 50) rgba($color-dark, .2);
  text-align: center;

  @include breakpoint(large) {
    padding: rem-calc(40 40 40 20);
  }

  &__heading {
    @include flex-grid-column(12, 0);

    @include breakpoint(large) {
      @include flex-grid-size(12);
    }
  }

  &__content {
    @include flex-grid-column(12, 0);
    max-width: rem-calc(550);

    @include breakpoint(large) {
      @include flex-grid-size(12);
      max-width: rem-calc(550);
    }
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    position: relative;
    padding-left: rem-calc(30);
    font-size: rem-calc(20);
    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
      font-size: rem-calc(28);
    }

    &::before{
      position: relative;
      // top: rem-calc(-5);
      // left: rem-calc(30);
      margin-top: rem-calc(-7);
      margin-right: rem-calc(12);
      color: $color-main;
      font-size: rem-calc(32);

      @include breakpoint(large) {
        font-size: rem-calc(34);
        // top: rem-calc(-10);
        // left: rem-calc(35);
      }
    }
  }

  // Drupal Webform
  form > div{
    @include flex-grid-row;
    @include flex-align(center, stretch);

    .webform-component-email{
      @include flex-grid-column(12, 0);

      @include breakpoint(large) {
        @include flex-grid-size(8);
      }
    }
    .webform-component-checkboxes{
      margin-bottom: rem-calc(11);

      @include breakpoint(xlarge) {
        order: 3;
        margin-bottom: 0;
      }

      .form-type-checkbox{
        display: flex;
        justify-content: flex-start;
        max-width: rem-calc(536);
        margin-top: 11px;
        text-align: left;

        .form-item-content{
          margin-right: rem-calc(11);
        }

        .form-item-label{
          label{
            margin-top: rem-calc(4);
            font-size: rem-calc(14);
            line-height: 1.4;
          }
        }
      }
    }
    .form-actions{
      @include flex-grid-column(12, 0);
      position: relative;

      @include breakpoint(large) {
        @include flex-grid-size(4);
      }
    }

    [type=submit]{
      //height: 100%;
      width: 100%;
    }
  }

  // Drupal Ajax
  .ajax-progress{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-btn;

    .throbber{
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      width: rem-calc(20);
      height: rem-calc(20);
      border: rem-calc(2) solid rgba(#fff, .3);
      border-top-color: #fff;
      animation: spin .4s infinite linear;
      background: none;
      margin: rem-calc(-10 0 0 -10);
    }

    .message{
      display: none;
    }
  }
}
