@mixin enter-transform ($position, $animation) {
  @if ($animation == 'perspective') {
    @if ($position == 'top') {
      transform: translateY(-0.75rem) rotateX(0);
    } @else if ($position == 'bottom') {
      transform: translateY(0.75rem) rotateX(0);
    } @else if ($position == 'left') {
      transform: translateX(-0.75rem) rotateY(0);
    } @else if ($position == 'right') {
      transform: translateX(0.75rem) rotateY(0);
    }
  } @else if ($animation == 'fade') {
    @if ($position == 'top') {
      transform: translateY(-0.75rem);
    } @else if ($position == 'bottom') {
      transform: translateY(0.75rem);
    } @else if ($position == 'left') {
      transform: translateX(-0.75rem);
    } @else if ($position == 'right') {
      transform: translateX(0.75rem);
    }
  } @else if ($animation == 'scale') {
    @if ($position == 'top') {
      transform: translateY(-0.75rem) scale(1);
    } @else if ($position == 'bottom') {
      transform: translateY(0.75rem) scale(1);
    } @else if ($position == 'left') {
      transform: translateX(-0.75rem) scale(1);
    } @else if ($position == 'right') {
      transform: translateX(0.75rem) scale(1);
    }
  }
}

@mixin leave-transform ($position, $animation) {
  @if ($animation == 'perspective') {
    @if ($position == 'top') {
      transform: translateY(0) rotateX(90deg);
    } @else if ($position == 'bottom') {
      transform: translateY(0) rotateX(-90deg);
    } @else if ($position == 'left') {
      transform: translateX(0) rotateY(-90deg);
    } @else if ($position == 'right') {
      transform: translateX(0) rotateY(90deg);
    }
  } @else if ($animation == 'shift') {
    @if ($position == 'top') {
      transform: translateY(0);
    } @else if ($position == 'bottom') {
      transform: translateY(0);
    } @else if ($position == 'left') {
      transform: translateX(0);
    } @else if ($position == 'right') {
      transform: translateX(0);
    }
  } @else if ($animation == 'scale') {
    @if ($position == 'top') {
      transform: translateY(0) scale(0);
    } @else if ($position == 'bottom') {
      transform: translateY(0) scale(0);
    } @else if ($position == 'left') {
      transform: translateX(0) scale(0);
    } @else if ($position == 'right') {
      transform: translateX(0) scale(0);
    }
  } @else if ($animation == 'none') {
    @if ($position == 'top') {
      transform: translateY(-0.75rem);
    } @else if ($position == 'bottom') {
      transform: translateY(0.75rem);
    } @else if ($position == 'left') {
      transform: translateX(-0.75rem);
    } @else if ($position == 'right') {
      transform: translateX(0.75rem);
    }
  }
}

@mixin circle-transform-leave ($position) {
  @if ($position == 'top') {
    transform: scale(0) translate(-50%, -50%);
  } @else if ($position == 'bottom') {
    transform: scale(0) translate(-50%, -50%);
  } @else if ($position == 'left') {
    transform: scale(0) translate(-50%, -33%);
  } @else if ($position == 'right') {
    transform: scale(0) translate(-50%, -33%);
  }
}

@mixin arrow ($position, $width) {
  @if ($position == 'top') {
    transform: rotate(-45deg);
    bottom: -$width/2;
  } @else if ($position == 'bottom') {
    transform: rotate(135deg);
    top: -$width/2;
  } @else if ($position == 'left') {
    transform: rotate(225deg);
    right: -$width/2;
    top: 50%;
  } @else if ($position == 'right') {
    transform: rotate(45deg);
    left: -$width/2;
    top: 50%;
  }
}

$positions: 'top', 'bottom', 'left', 'right';
$origins: bottom, top, right, left;

.tippy-touch {
  cursor: pointer !important;
}

.tippy-popper {
  visibility: hidden;
  max-width: 400px;
  perspective: 800px;
  z-index: 9999;

  &.html-template {
    max-width: 96%;
    max-width: calc(100% - 20px);
  }

  @each $position in $positions {
    &[x-placement=#{$position}] {

      [x-arrow] {
        width: 0.75rem;
        height: 0.75rem;
        @include arrow($position, 0.75rem);

        &.arrow-small {
          width: 0.5rem;
          height: 0.5rem;
          @include arrow($position, 0.5rem);
        }

        &.arrow-big {
          width: 1rem;
          height: 1rem;
          @include arrow($position, 1rem);
        }
      }

      $circle-origins: 0% 50%, 0% -50%, 50% 5%, -50% 5%;

      [x-circle] {
        transform-origin: nth($circle-origins, index($positions, $position));

        &.enter {
          opacity: 1;
          transform: scale(1) translate(-50%, -47.5%);
        }

        &.leave {
          opacity: 0;
          @include circle-transform-leave($position);
        }
      }

      [data-animation=none] {
        &.enter {
          opacity: 1;
          @include enter-transform($position, 'fade');
        }

        &.leave {
          opacity: 1;
          @include leave-transform($position, 'none');
        }
      }

      [data-animation=perspective] {
        transform-origin: nth($origins, index($positions, $position));

        &.enter {
          opacity: 1;
          @include enter-transform($position, 'perspective');
        }

        &.leave {
          opacity: 0;
          @include leave-transform($position, 'perspective');
        }
      }

      [data-animation=fade] {
        &.enter {
          opacity: 1;
          @include enter-transform($position, 'fade')
        }
        &.leave {
          opacity: 0;
          @include enter-transform($position, 'fade')
        }
      }

      [data-animation=shift] {
        &.enter {
          opacity: 1;
          @include enter-transform($position, 'fade')
        }
        &.leave {
          opacity: 0;
          @include leave-transform($position, 'shift')
        }
      }

      [data-animation=scale] {
        &.enter {
          opacity: 1;
          @include enter-transform($position, 'scale')
        }
        &.leave {
          opacity: 0;
          @include leave-transform($position, 'scale')
        }
      }

    }
  }
}

.tippy-tooltip {
  position: relative;
  color: white;
  //border-radius: 4px;
  //padding: 0.5rem 1rem;
  text-align: center;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333;

  @include breakpoint(large) {
    max-width: rem-calc(600);
  }

  .img-plan{
    box-shadow: rem-calc(0 20 50) rgba($color-dark, .2);
  }

  &--small {
    padding: 0.4rem 0.8rem;
    font-size: 90%;
  }

  &--big {
    padding: 0.75rem 1.25rem;
    font-size: 120%;
  }

  [x-arrow] {
    z-index: -1;
    position: absolute;
    background-color: #333;
    border: 1px solid;
    border-color: transparent transparent #333 #333;
  }

  &[data-animatefill] {
    overflow: hidden;
    background-color: transparent;
  }

  &[data-inertia] {
    // Safari/Webkit can't go out of range
    -webkit-transition-timing-function: cubic-bezier(.53,1,.36,.85);
    transition-timing-function: cubic-bezier(.53,2,.36,.85);
  }

  [x-circle] {
    position: absolute;
    will-change: transform;
    background-color: #333;
    border-radius: 50%;
    width: 120%;
    width: calc(100% + 2rem);
    left: 50%;
    transition: all ease-out;
    z-index: -1;
    opacity: 0;
    overflow: hidden;

    &::before {
      content: '';
      padding-top: 100%;
      padding-top: calc(75% + 2rem);
      float: left;
    }
  }

  // Themes

  &.light-theme {
    color: #203d5d;
    box-shadow: 0 0 16px -4px rgba(0,20,40,0.2), 0 0 80px -12px rgba(0,20,40,0.3);
    background-color: #f3f6f9;
    border: 1px solid #ced2e2;

    [x-circle] {
      background-color: #f3f6f9;
    }

    [x-arrow] {
      background-color: #f3f6f9;
      border-color: transparent transparent #ced2e2 #ced2e2;
    }

    &[data-animatefill] {
      background-color: transparent;
    }
  }

  &.transparent-theme {
    background-color: rgba(0,0,0,0.8);

    &[data-animatefill] {
      background-color: transparent;
    }

    [x-circle] {
      background-color: rgba(0,0,0,0.8);
    }

    [x-arrow] {
      width: 0;
      height: 0;
      background-color: transparent;
      border: 0.375rem solid transparent;
      border-bottom-color: rgba(0,0,0,0.8);
      border-left-color: rgba(0,0,0,0.8);
      border-radius: 0;

      &.arrow-small {
        border-width: 0.25rem;
      }

      &.arrow-big {
        border-width: 0.5rem;
      }
    }
  }
}

// Fix for iOS animateFill circle
.tippy-iOS-fix {
  &.tippy-popper[x-placement=top] [x-circle].leave {
    transform: scale(0) translate3d(-50%, -50%, 0)
  }
  &.tippy-popper[x-placement=bottom] [x-circle].leave {
    transform: scale(0) translate3d(-50%, -50%, 0)
  }
  &.tippy-popper[x-placement=left] [x-circle].leave {
    transform: scale(0) translate3d(-50%, -33%, 0)
  }
  &.tippy-popper[x-placement=right] [x-circle].leave {
    transform: scale(0) translate3d(-50%, -33%, 0)
  }
}

@media (max-width: 500px) {
  .tippy-popper {
    max-width: 96%;
    max-width: calc(100% - 20px);
  }
}
