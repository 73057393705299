/**
* Agenda Component
* used on some pages
* Use Foundation Tabs and custom styls below
*/

.agenda{

  // Agenda Head
  &__head{
    @include flex-grid-row;
    border-bottom: $color-divider 1px solid;

    // Dates tabs
    &__dates{
      @include flex-grid-column(12, 0);

      // Swiper Carousel for Tabs
      .swiper-slide{
        font-size: rem-calc(18);
        width: auto;
        padding: rem-calc(13 20 11);
        text-align: center;
        font-weight: 600;
        transition: background-color .2s, color .2s;

        // Hover on not active
        &:not(.swiper-slide-active){
          cursor: pointer;

          &:hover {
            background-color: $color-light;
          }
        }
      }
      
      .swiper-slide-active{
        background-color: $color-main;
        color: #fff;
      }
    }

  }

  // Agenda Body
  &__body{

    // Swiper Carousel for Content
    .swiper-wrapper{
      transition-timing-function: $easeOutQuart;
    }
    .swiper-slide{
      padding: rem-calc(25 0 10);

      @include breakpoint(large) {
        padding: rem-calc(50 0 20);
      }
    }
  }
}
