/**
* Google Maps
*/


.gmap-popup{

  .views-field-title{
    text-align: center;
    padding: rem-calc(15 10 12 20);
  }

  span:first-child, a{
    font-family: $font-main;
    color: $color-dark;
    font-weight: 600;
    font-size: rem-calc(18);
  }

  a{

    &::after{
      @include glyph;
      display: inline-block;
      vertical-align: top;
      content: "\62";
      font-size: rem-calc(20);
    }
  }
}
