/* ==========================================================================
header.scss
========================================================================== */


/**
* Header
*/

.header {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  @include breakpoint(large) {
    position: relative;
    z-index: 1;
    height: rem-calc(130);
  }

  // ### Centering Wrapper
  &__content {
    @include flex-grid-row;
    @include flex-align(justify, middle);
    height: 100%;
  }

  // ### Logo
  &__logo {
    @include flex-grid-column(shrink);
    padding-top: rem-calc(10);
    padding-bottom: rem-calc(10);
    display: flex;
    @include flex-align(justify, middle);

    @include breakpoint(large) {
      @include flex-grid-size(12);
      // padding-top: 0;
      padding-bottom: 0;
    }

    a {
      display: block;
    }

    img {
      display: block;
      // width: auto;
      height: rem-calc(45);
      object-fit: contain;

      @include breakpoint(large) {
        height: rem-calc(60);
      }
    }

    .logos-ville {
      display: flex;
      @include flex-align(right, middle);

      @include breakpoint(medium down) {
        order: 1;
        margin-left: rem-calc(10);
      }

      a {
        margin-left: rem-calc(10);
      }
    }

    .social-pages {
      @include breakpoint(medium down) {
        display: none;
      }
    }
  }

  // ### Navigation
  // (all devices)
  &__nav {
    @include flex-grid-column(12);
    position: relative;

    @include breakpoint(large) {
      //@include flex-grid-size(10);
      @include flex-grid-size(12);
    }

    .social-pages {
      
      @include breakpoint(large) {
        display: none;
      }
    }

    .menu>li{
      position: relative;

      &::before{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: rem-calc(10);
        background-color: $color-alt2;
        opacity: .1;
        transform: translateX(-50%) scaleY(0);
        transform-origin: 50% 100%;
        transition: transform .3s $easeOutQuart, opacity .2s;
      }
      @include breakpoint(large) {
        &:hover::before{
          transform: translateX(-50%) scaleY(1);
        }
      }
    }
    .menu>li.is-active::before,
    .menu>li.is-active-trail::before{
      transform: translateX(-50%) scaleX(1);
    }
    @include breakpoint(large) {
      .menu>li.is-active:hover::before,
      .menu>li.is-active-trail:hover::before{
        transform: translateX(-50%) translateY(120%) scaleX(1) scaleY(.25);
      }
    }
    .menu>li>a{
      position: relative;
      z-index: 1;
      font-weight: bold;
      font-size: rem-calc(20);
      color: $color-dark;
    }
    .menu>li.is-active>a,
    .menu>li.is-active-trail>a{
      background: none;
    }

    // 2nd Level
    .menu .menu{
      @include menu-direction(vertical);
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transform: scaleY(0);
      transform-origin: 0 0;
      transition: transform .25s $easeOutBack, max-height .25s, opacity .2s;

      li{
        margin-left: rem-calc(20);

        @include breakpoint(large) {
          margin: 0 !important;
        }
      }

      @include breakpoint(large) {
        position: absolute;
        min-width: rem-calc(220);
        top: 100%;
        padding: rem-calc(15 15 13) !important;
        justify-content: flex-start !important;
        background-color: #fff;
        box-shadow: rem-calc(0 15 30) rgba($color-dark, .2);
      }
    }
    // Trigger 2nd level
    .menu>li.expanded{
      cursor: pointer;

      &::after{
        @include glyph;
        position: absolute;
        top: rem-calc(7);
        right: rem-calc(15);
        content: '\64';
        font-size: rem-calc(22);

        @include breakpoint(large) {
          right: 0;
        }
      }

      & > a{
        cursor: pointer;

        @include breakpoint(large) {
          padding-right: rem-calc(30) !important;
        }
      }
    }
    .menu>li.expanded:hover::after{
      
      @include breakpoint(medium down) {
        transform: rotate(180deg);
      }
    }
    .menu>li.expanded:hover .menu{
      opacity: 1;
      max-height: rem-calc(999);
      transform: scaleY(1);
    }
  }

  // ### Navigation Mobile
  // (Small + Medium Sizes)
  &__nav.mobile{
    @include hide-for(large);
    padding-top: rem-calc(85);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: #fff;
    width: 100%;
    max-width: rem-calc(280);
    transform: translateX(100%);

    @include breakpoint(medium only) {
      max-width: 50vw;
    }

    .menu{
      @include menu-direction(vertical);
    }
    .menu>li{
      opacity: 0;
    }
  }


  // ### Navigation
  // Desktop devices (Large+)
  &__nav:not(.mobile){
    @include show-for(large);

    .menu{
      justify-content: center;
      // padding-right: rem-calc(60);
      
      @include breakpoint(xlarge) {
        padding-top: rem-calc(5);
        padding-bottom: rem-calc(5);
      }
    }
    .menu>li{
      margin: rem-calc(0 6);
    }
    .menu>li>a{
      padding: rem-calc(11 10 10);
    }
    .menu>li.is-active>a,
    .menu>li.is-active-trail>a{
      // Hover only on desktop

    }
  }

  // ### Trigger Btn
  &__open{
    @include hide-for(large);
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
  }
}


#trigger-menu{
  position: absolute;
  top: rem-calc(9);
  right: rem-calc(8);
  z-index: 13;
  margin: 0;
  border: none;
  width: rem-calc(60);
  height: rem-calc(50);
  font-size: 0;
  background: none;
  @extend .hide-for-large;

  @include breakpoint(large) {
    top: rem-calc(35);
    right: rem-calc(30);
  }

  span{
    @include disable-mouse-outline;
    position: absolute;
    width: rem-calc(22);
    height: rem-calc(2);
    top: 50%;
    left: 50%;
    background: $color-main;
    transform: translate(-50%, -50%);

    &::before,
    &::after{
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      transition: transform .2s $easeOutBack, background .3s;
    }
    &::before{
      transform: translateY(rem-calc(-6));
    }
    &::after{
      transform: translateY(rem-calc(6));
    }

    // Menu opened
    .nav-opened &{
      background: none;

      &::before{
        background: $color-main;
        transform: rotate(45deg);
      }
      &::after{
        background: $color-main;
        transform: rotate(-45deg);
      }
    }
  }
}


// ### Backdrop
.backdrop{
  @include hide-for(large);
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#223, .75);
  opacity: 0; // velocityJS
  display: none; // velocityJS
}
