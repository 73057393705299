@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes messagesIn {
  0%{
    opacity: 0;
    transform: scale(.7) translateY(-20%);
  }
  100%{
    opacity: 1;
    transform: none;
  }
}
