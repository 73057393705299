
// Container
.accordeon {


  .archives & {
    max-width: rem-calc(1200);
    width: 91.66667%;
    margin: 0 auto;

    @include breakpoint(large) {
      width: 58.33333%;
      margin-bottom: rem-calc(80);
    }
  }

}

.accordion-title {
  font-weight: 600;
  color: $color-dark;
  font-size: rem-calc(21);
  line-height: 1.4;
  transition: background-color .3s, color .3s;

  &:focus,
  &:hover,
  &:active {
    color: $color-main;
    background-color: $color-light;
  }

  @include breakpoint(large) {
    font-size: rem-calc(20);
    padding: rem-calc(20 40 15);
  }
}

.accordion-content {
  @include standard_styles;
  @include clearfix;
  color: rgba($color-dark, .7);
  line-height: 1.7;
  font-size: rem-calc(16);


  p{
    margin-left: 0;
    margin-right: 0;
    line-height: inherit;


  }
  h2, h3, h4{
    margin-left: 0;
    margin-right: 0;
  }

  @include breakpoint(large) {
    padding: rem-calc(25 40 20);

    p > img.img-left{
      margin-left: 0;
    }
    p > img.img-right{
      margin-right: 0;
    }
    .img-full{
      margin-left: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
    }
  }
}
