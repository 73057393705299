/**
* News
* Page layout + Page detail adjustements
* News items is located in components/_lists.scss
*/

// Main layout
.view-id-actualites{
  @include flex-grid-row;
  @include flex-align(center, top);

  & > .view-content{
    @include flex-grid-column(11);

    @include breakpoint(large) {
      @include flex-grid-size(9);
    }
  }

  & > .item-list{
    @include flex-grid-column(11);

    @include breakpoint(large) {
      @include flex-grid-size(9);
    }
  }

}
