/**
* Artistes / Auteurs
* Page layout
* Masonry is customised in components/_masonry.scss
*/

// Main layout
.view-id-artistes{
  @include flex-grid-row;
  @include flex-align(center, top);

  .masonry{
    @include flex-grid-column(11, 0);
  }

}
