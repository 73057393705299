/* ==========================================================================
forms.scss
========================================================================== */


/**
* Inputs global styles
* See `common/_settings` file for Foundation overrides
*/
[type=color],
[type=date],
[type=datetime-local],
[type=datetime],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
textarea{
  margin-bottom: 0;
  padding: rem-calc(14 20);
  height: auto;
  transition: background-color .3s;

  &:focus{
    background-color: scale-color($color-paleblue, $lightness : -5%);
  }
}

[type=button]:not(.mfp-close),
[type=submit]{
  background-color: $color-btn;
  border: none;
  padding: rem-calc(0 14);
  color: #fff;
  font-family: $font-alt;
  font-size: rem-calc(14);
  font-weight: 700;
  box-shadow: none;
  cursor: pointer;
  transition: background-color .3s;
  line-height: rem-calc(46);
  text-transform: uppercase;
  letter-spacing: 0.05em;

  &:hover{
    background-color: scale-color($color-btn, $lightness : -10%);
  }
}

select{
  margin-bottom: 0;

  &:focus{
    background-color: #fff;
  }
}

// Form text
.form-text {

  &:hover {

  }

  &:focus {

  }
}

// Textarea
.form-textarea-wrapper {

  .form-textarea {

    &:hover {

    }

    &:focus {

    }
  }
}


// Select
select {

  &:hover {

  }

  &:focus {

  }

  option {

  }
}


// Webform
form.webform-client-form{

  // Container of each element
  .webform-component{

    // Labels
    > label {

    }

    .form-item {
      label {

      }
    }
  }

  // Select
  select {

    option {

    }
  }

  // Form text
  .form-text {

    &:hover {

    }

    &:focus {

    }
  }

  // Textarea
  .webform-component-textarea {

  }
  .form-textarea-wrapper {

    .form-textarea {

      &:hover {

      }

      &:focus {

      }
    }
  }

  // Radios
  .webform-component-radios {

    label ~ .form-radios {

    }

    .form-type-radio {
      label {

      }
    }
  }

  // Checkboxes
  .webform-component-checkboxes {

    label ~ .form-checkboxes {

    }

    .form-type-checkbox {

      label {

      }
    }
  }

  // Upload file
  .form-managed-file {

    .form-file {

    }

    .form-submit {

      &:hover {

      }
    }
  }

  // Description Field
  .description {

  }

  // Inline content
  .webform-container-inline{


    &.webform-component {

      // Labels
      > label {

      }
    }

    // Form text
    .form-text {

    }

    // Select
    select {

      &:hover{

      }
    }

    // Textarea
    .form-textarea-wrapper {

      .form-textarea {

      }
    }


    // Upload file
    .form-managed-file {

    }

    // Radios
    .form-type-radio{

    }

  }

  // Button
  .form-actions {
    .webform-submit {

      &:hover {

      }
    }
  }

}
