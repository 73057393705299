/**
* Cards Components
* used on many pages
* Some layout styles (flex) are used from Foundation Cards components
*/

.card {
  @include hover_animation;
  display: block;
  background-color: #fff;
  box-shadow: rem-calc(0 20 50) rgba($color-dark, .2);
  border: none;

  .bloc--bigtitle &{
    box-shadow: none;
  }

  /**
  * Generic Styles
  */

  // Card Thumb Image
  &__thumb{

  }

  // Card is full clickable
  &__link{
    display: block;
  }

  // Inner content
  &__content{
    padding: rem-calc(15 15 12);

    @include breakpoint(large) {
      padding: rem-calc(20 20 17);
    }
    @include breakpoint(xlarge) {
      padding: rem-calc(23 23 20);
    }
  }

  // Card Title
  &__title{
    font-weight: 700;
    font-size: rem-calc(22);
    color: $color-dark;
    line-height: 1.15;
    letter-spacing: -.01em;
    margin: rem-calc(0 0 6);

    @include breakpoint(large) {
      font-size: rem-calc(30);
    }
  }

  // Card type
  &__type {
    font-weight: 600;
    font-size: rem-calc(16);
    color: $color-main;
  }

  // Card Excerpt
  &__excerpt{
    color: $color-dark;
    font-weight: 500;
    margin-top: rem-calc(5);

    p{
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      margin: 0;
      opacity: .7;
    }

    @include breakpoint(large) {
      font-size: rem-calc(17);
    }
  }

  /**
  * Cards Variations
  */
  // Artistes
  &.agenda &__title,
  &.artistes &__title {
    // color: $color-alt3;
    font-size: rem-calc(20);

    @include breakpoint(large) {
      font-size: rem-calc(25);
    }
  }
  // Video
  &.video &__link{

    &::before,
    &::after{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::after{
      content: '';
      border-radius: 100%;
      width: rem-calc(74);
      height: rem-calc(74);
      background-color: $color-divider;
    }
    &:before{
      font-size: rem-calc(20);
      z-index: 1;
      color: $color-main;
    }

  }

}
