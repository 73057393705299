/* ==========================================================================
fonts.scss
========================================================================== */

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/hinted-Gilroy-ExtraBold.woff2') format('woff2'),
  url('../fonts/hinted-Gilroy-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/hinted-Gilroy-Bold.woff2') format('woff2'),
  url('../fonts/hinted-Gilroy-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/hinted-Gilroy-SemiBold.woff2') format('woff2'),
  url('../fonts/hinted-Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/hinted-Gilroy-Medium.woff2') format('woff2'),
  url('../fonts/hinted-Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/hinted-Gilroy-Regular.woff2') format('woff2'),
  url('../fonts/hinted-Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Plantin';
  src: url('../fonts/PlantinMTPro-Light.woff2') format('woff2'),
  url('../fonts/PlantinMTPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Plantin';
  src: url('../fonts/PlantinMTPro-LightItalic.woff2') format('woff2'),
  url('../fonts/PlantinMTPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Plantin';
  src: url('../fonts/PlantinMTPro-Regular.woff2') format('woff2'),
  url('../fonts/PlantinMTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "sites-cultures";
  src:url("../fonts/sites-cultures.eot");
  src:url("../fonts/sites-cultures.eot?#iefix") format("embedded-opentype"),
  url("../fonts/sites-cultures.woff") format("woff"),
  url("../fonts/sites-cultures.ttf") format("truetype"),
  url("../fonts/sites-cultures.svg#sites-cultures") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "sites-cultures" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "sites-cultures" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-play:before {
  content: "\61";
}
.icon-ios-arrow-forward:before {
  content: "\62";
}
.icon-twitter:before {
  content: "\65";
}
.icon-youtube-play:before {
  content: "\66";
}
.icon-facebook:before {
  content: "\67";
}
.icon-vimeo:before {
  content: "\63";
}
.icon-ios-arrow-down:before {
  content: "\64";
}
.icon-ios-arrow-back:before {
  content: "\68";
}
.icon-list-bullet:before {
  content: "\69";
}
.icon-map:before {
  content: "\6a";
}
.icon-paper-plane-o:before {
  content: "\6b";
}
.icon-download:before {
  content: "\6c";
}
.icon-close-round:before {
  content: "\6d";
}
