/* ==========================================================================
    homepage.scss
  =========================================================================== */

.js-photoswipe{

  a{
    @include hover_animation;
    display: block;
    box-shadow: rem-calc(0 30 60 -8) rgba(darken($color-alt, 40%), .3);

    @include breakpoint(large) {
      height: rem-calc(550);
      overflow: hidden;
    }
  }

  img{
    @include breakpoint(large) {
      position: relative;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.pswp__img{
  box-shadow: rem-calc(0 25 60) rgba($color-dark, .2);
}
