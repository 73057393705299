/**
* Tarte au citron 2019 (Best Script Ever 😅)
* @version SCSS 1.1.0 (pour les vieilles version de foundation)
*/

/**
 * CSS Rebase
 */

// Root (contains everything)
#tarteaucitronRoot {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    color: #333;
    font-size: rem-calc(15);
    z-index: 100; // Au dessus d'un quelconque header fixe etc.
    font-family: $font-main;

    ul {
        margin: 0;
    }
  
    // Backdrop
    #tarteaucitronBack {
      display: none;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      background: rgba(38, 38, 62, 0.5);
      left: 0;
      z-index: 2;
      animation: tacBackdrop 0.4s ease both;
    }
  
    // Barre de Cookie
    #tarteaucitronAlertBig {
      display: none;
      position: fixed;
      width: calc(100% - #{rem-calc(30)});
      max-width: rem-calc(345);
      left: rem-calc(15);
      bottom: rem-calc(15); // Bottom is default
      padding: rem-calc(20 20 15);
      z-index: 1;
      color: inherit;
      background: #fff;
      border-radius: rem-calc(5);
      font-size: rem-calc(13);
      box-shadow: rem-calc(0 12 30) rgba(38, 38, 62, 0.2);
      animation: tacBannerEnter 0.4s ease(out-quick) both;
  
      @include breakpoint(medium) {
  
      }
    }
  
    // Disclaimer
    #tarteaucitronDisclaimerAlert {
      font-size: rem-calc(14);
      display: block;
    }
  
    // Btn OK Tout accepter
    #tarteaucitronPersonalize {
      @extend .button;
      display: block;
      width: 100%;
      margin: rem-calc(15 0);
      border-radius: rem-calc(3);
      text-transform: uppercase;
      font-weight: bold;
      font-size: inherit;
      letter-spacing: 0.06em;
      background-color: $color-main;

      &:hover {
        background-color: $color-main;
      }
    }
  
    // Btn Personnaliser
    #tarteaucitronCloseAlert {
      @extend .button;
      @extend .hollow;
      display: block;
      width: 100%;
      margin: 0;;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 0.06em;
      font-size: rem-calc(12);
      border-color: #fff;
      color: $color-main;
  
      &:focus,
      &:hover {
        color: $color-main;
        border-color: #fff;
      }
    }
  
    // Scroll Percentage
    #tarteaucitronPercentage {
      display: none !important;
    }
  
    // Panel "Personnaliser"
    #tarteaucitron {
      display: none;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      bottom: 0; // Bottom is default
      margin: 0;
      border: 0;
      z-index: 2;
      background: #fff;
      animation: tacPersonnalizeEnter 0.5s ease(out-quick) both;
  
      @include breakpoint(medium) {
        width: rem-calc(640);
        left: 50%;
        margin-left: rem-calc(-320);
        top: 5vh !important;
        bottom: 5vh !important;
        height: 90vh;
        border-radius: rem-calc(5);
        box-shadow: rem-calc(0 15 30) rgba(38, 38, 62, 0.2);
      }
  
      // Personnaliser Heading
      .tarteaucitronMainLine {
  
        .tarteaucitronName {
          font-size: rem-calc(20);
          font-weight: normal;
          text-align: center;
  
          b {
            font-size: inherit;
            font-weight: inherit;
          }
        }
  
        .tarteaucitronAsk {
          justify-content: center;
          margin: rem-calc(20 0 30) !important
        }
  
        .tarteaucitronAllow,
        .tarteaucitronDeny {
          padding: rem-calc(15);
          opacity: 1 !important;
            
          /**
           * Cas particuliers pour les gros boutons
           * comportaement trop chelou : quand on a cliqué sur un des gros bouttons, l'autre passe en opacité 0.4
           * tout en conservant la couleur verte / rouge. Donc il faut aussi tester ce cas là
           */
          // Button is Red => better red
            &[style*="background-color: rgb(156, 26, 26)"] {
                background: #dc3545 !important;
                color: #fff !important;
                border-color: #dc3545;
            }
            // Button is Grey => white
            &[style*="background-color: rgb(128, 128, 128)"] {
                background: #fff !important;
            }
            // Button is Green => better green
            &[style*="background-color: rgb(27, 135, 11)"] {
                background: #28a745 !important;
                color: #fff !important;
                border-color: #28a745;
            }
            // Siblings Clicked : Opacity 0.4
            &[style*="opacity: 0.4"] {
                background: #fff !important;
                color: #999 !important;
                border-color: #bbb !important;

                &:hover {
                    color: $color-main !important;
                    border-color: $color-main !important;
                }
            }
        }
      }
  
      // Personnaliser Content
      #tarteaucitronServices {
        padding: rem-calc(20 20 15);
        // overflow-y: scroll; /* has to be scroll, not auto */
        // -webkit-overflow-scrolling: touch;
      }
  
      // Heading Infobox
      .tarteaucitronInfoBox,
      .tarteaucitronH1 {
        display: none;
      }
  
      // Tooltip InfoBox
      .tarteaucitronInfoBox {
        background: #fff;
        margin: 0;
        width: calc(100% - #{rem-calc(40)});
        padding: rem-calc(15 20);
        font-size: rem-calc(14);
        border-radius: rem-calc(5);
        margin-bottom: rem-calc(15);
        position: absolute;
        box-shadow: inset 0 0 0 1px #e5e5e5, rem-calc(0 10 20) $shadow-soft;
        animation: tacInfoboxEnter 0.2s ease(out-back) both;
      }
  
      // Buttons Group
      .tarteaucitronAsk {
        margin: rem-calc(10 0 15) !important;
        display: flex;
      }
      .tarteaucitronAllow,
      .tarteaucitronDeny {
        @extend .button;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.06em;
        font-size: rem-calc(12);
        flex: 1 1 auto;
        max-width: rem-calc(160);
        border-radius: rem-calc(5);
        margin: 0;
        transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  
        &:hover,
        &:focus {
          border-color: $color-main;
          color: $color-main;
        }
        // Button is Red => better red
        &[style="background-color: rgb(156, 26, 26);"] {
          background: #dc3545 !important;
          color: #fff !important;
          border-color: #dc3545;
        }
        // Button is Grey => white
        &[style="background-color: rgb(128, 128, 128);"] {
          background: #fff !important;
        }
        // Button is Green => better green
        &[style="background-color: rgb(27, 135, 11);"] {
          background: #28a745 !important;
          color: #fff !important;
          border-color: #28a745;
        }
      }
      .tarteaucitronAllow,
      .tarteaucitronDeny {
        @extend .hollow;
        margin-right: rem-calc(2);
        // background-color: #fff !important;
        color: #999;
        border-color: #bbb;
      }
    }
  
    // Close Personnaliser
    #tarteaucitronClosePanel {
      position: absolute;
      top: 0;
      right: 0;
      // width: rem-calc(40);
      height: rem-calc(32);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
      background-color: #fff;
      background-size: rem-calc(18 18);
      background-position: rem-calc(10 7);
      background-repeat: no-repeat;
      overflow: hidden;
      padding: rem-calc(0 10 0 32);
      z-index: 10;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.1em;
      font-size: rem-calc(11);
      color: inherit;
      border-radius: rem-calc(4);
      transition: background-color .3s;
  
      &:hover {
        background-color: #f5f5f5;
      }
  
      @include breakpoint(medium) {
        top: rem-calc(10);
        right: rem-calc(10);
      }
    }
  
    // Tous les "+"
    .tarteaucitronName [onclick],
    .tarteaucitronTitle [onclick] {
      font-size: rem-calc(17);
      line-height: rem-calc(24);
      padding: 0;
      overflow: hidden;
      background-size: rem-calc(16);
      position: relative;
  
      &::first-letter {
        margin-right: rem-calc(15);
      }
  
      &::after {
        content: '';
        width: rem-calc(26);
        height: rem-calc(24);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: #f5f5f5 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-help-circle'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpath d='M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3'%3E%3C/path%3E%3Cline x1='12' y1='17' x2='12' y2='17'%3E%3C/line%3E%3C/svg%3E") no-repeat 50% 50%;
        background-size: rem-calc(24 24);
      }
    }
  
    // Categories
    .tarteaucitronTitle {
      background: #f5f5f5;
      padding: rem-calc(5 20);
      width: calc(100% + #{rem-calc(40)});
      margin-left: rem-calc(-20);
    }
  
    // Services
    .tarteaucitronLine {
      margin: rem-calc(15 0);
      width: calc(100% + #{rem-calc(40)});
      margin-left: rem-calc(-20);
      padding: rem-calc(0 20);
      border: none !important;
  
      &:not(.tarteaucitronMainLine) {
  
        @include breakpoint(medium) {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
  
  
      br { display: none; }
      b, span { display: block; }
  
      b {
        font-weight: bold;
      }
  
      .tarteaucitronListCookies {
        color: #999;
        font-size: rem-calc(14);
      }
      a[rel="noopener"] {
        text-transform: uppercase;
        letter-spacing: 0.04em;
        font-size: rem-calc(11);
      }
    }
  
    // Utility
    .tarteaucitronHidden {
      display: none;
    }
  }
  
  /**
   * CSS Animations
   */
  
  @keyframes tacBannerEnter {
    0% {
      opacity: 0;
      transform: translate3d(0, 15px, 0);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 0px, 0);
    }
  }
  
  @keyframes tacInfoboxEnter {
    0% {
      transform: translate3d(0, -15px, 0) scale(0.92);
    }
   100% {
      transform: translate3d(0, 0px, 0) scale(1);
    }
  }
  
  @keyframes tacPersonnalizeEnter {
    0% {
      opacity: 0;
      transform: scale(0.94);
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes tacBackdrop {
    0% {
      opacity: 0.01;
    }
    100% {
      opacity: 0.99;
    }
  }
  