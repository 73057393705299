/**
* Filters
*/

// Filters
.view-filters{
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-paleblue;
  opacity: 0; // VelocityJS
  display: none; // VelocityJS
}
// Drupal override
.views-exposed-widgets{
  @include flex-grid-row;
  @include flex-align(left, middle);
  flex-wrap: wrap;
  margin-bottom: 0;
  height: rem-calc(65);

  @include breakpoint(large) {
    height: rem-calc(120);
    flex-wrap: nowrap;
  }
}
.views-exposed-form .views-exposed-widget{
  float: none;
  padding: 0;
  @include flex-grid-column(6);

  &:nth-child(1){
    @include flex-grid-size(7);
    @include flex-grid-row;

    // Label only visible on large
    label{
      @include show-for(large);
      @include flex-grid-size(5);
      text-align: right;
      font-size: rem-calc(21);
      font-weight: normal;
      color: $color-dark;
      padding-right: rem-calc(80);
      position: relative;

      &::after{
        content: '';
        position: absolute;
        top: 45%;
        right: rem-calc(15);
        width: rem-calc(50);
        height: rem-calc(2);
        background-color: $color-main;
      }
    }
    // Select
    .views-widget{
      @include flex-grid-size(12);

      @include breakpoint(large) {
        @include flex-grid-size(7);
      }
    }
  }
  &:nth-child(2){
    @include flex-grid-size(5);
    text-align: right;

    @include breakpoint(large) {
      text-align: left;
    }
  }

  .form-submit{
    margin: 0;
  }
}

// Filters options
.filters-options{
  @include flex-grid-row;
  @include flex-align(center, top);
  width: 100%;
  margin-bottom: rem-calc(50);

  &__content{
    @include flex-grid-column(11, 0);
    position: relative;
  }

  &__widget{
    position: absolute;
    top: rem-calc(-84);

    @include breakpoint(large) {
      //top: rem-calc(-47);
    }

    &.left{
      position: static;
      margin: rem-calc(-20 0 20);
      text-align: center;
      font-weight: normal;

      @include breakpoint(large) {
        position: absolute;
        left: 0;
        margin: 0;
        text-align: left;
      }

      span{
        color: $color-main;
      }
    }
    &.right{
      right: 0;
    }
    &.full{
      position: static;
      text-align: center;
    }
  }

  .button{
    @include disable-mouse-outline;
    display: inline-block;
    margin: 0;
    border: none;
    background: none;
    color: $color-dark;
    font-family: $font-main;
    font-size: rem-calc(18);
    font-weight: 600;
    width: rem-calc(44);
    height: rem-calc(44);
    line-height: rem-calc(49);
    padding: rem-calc(0 0 0 44);
    border-radius: rem-calc(44);
    white-space: nowrap;
    overflow: hidden;
    position: relative;

    @include breakpoint(large) {

      &:not(.next):not(.prev){
        border-radius: 0;
        width: auto;
      }

      &:hover{
        color: $color-main;
      }
    }

    // Circle
    &::after{
      //@include hide-for(large);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      box-shadow: inset rem-calc(0 0 0 1) $color-divider;
    }
    &:not(.next):not(.prev){
      &::after{
        @include hide-for(large);
      }
    }


    // Icon
    i{
      &::after{
        @include glyph;
        position: absolute;
        top: rem-calc(11);
        left: rem-calc(10);
        font-size: rem-calc(24);
        transform-origin: 50% 45%;
        transition: transform .2s;

        @include breakpoint(large) {
          top: rem-calc(10);
          left: rem-calc(15);
        }
      }
    }

    // Toggle Filters
    &.js-toggle-filters i::after{
      content: "\64";

      .filters-opened &{
        transform: rotate(180deg);
      }
    }
    // Back to list
    &.back-button i::after{
      content: "\69";
    }
    // Nav Prev/Next
    &.prev::before,
    &.next::before{
      @include glyph;
      position: absolute;
      top: rem-calc(10);
      left: rem-calc(11);
      font-size: rem-calc(24);
    }
    &.prev::before{ content: "\68"; }
    &.next::before{ content: "\62"; }

    // Jump to map
    &.button-map i::after{ content: "\6a"; }

    // Download
    &.download{
      width: auto;
      padding-right: rem-calc(20);
      padding-left: rem-calc(50);
      border-radius: rem-calc(50) !important;
      box-shadow: inset rem-calc(0 0 0 2) $color-main;
      color: scale-color($color-main, $lightness: -10%);

      i::after{
        transition: color .2s;
      }

      @include breakpoint(large) {
        transition: box-shadow .2s, color .2s;

        &:hover{
          box-shadow: inset rem-calc(0 0 0 2) $color-main, inset rem-calc(0 -50 0 0) $color-main;
          color: #fff;

          i::after{ color: #fff; }
        }
      }

      // Circle
      &::after{
        display: none;
        //box-shadow: inset rem-calc(0 0 0 2) $color-main;
      }
    }
    &.download i::after{
      content: "\6c";
      font-size: rem-calc(20);
      left: rem-calc(18);
      color: scale-color($color-main, $lightness: -10%);
    }
    // Cancel
    &.cancel{
      width: auto;
      border-radius: 0;
      padding-left: rem-calc(34);
      box-shadow: none;
      font-size: rem-calc(14);
      color: rgba($color-dark, .6);

      &:hover{ color: rgba($color-dark, 1); }

      &::after{ display: none; }
      &::before{
        @include glyph;
        content: "\6d";
        position: absolute;
        top: rem-calc(14);
        left: rem-calc(14);
        opacity: .8;
        //font-size: rem-calc(24);
      }
    }
  }
}
