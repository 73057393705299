/* ==========================================================================
pagination.scss
========================================================================== */

/* Pagination */
// Settings are customised in `common/_settings.scss`
.item-list {
  margin: rem-calc(25 0);

  ul.pager {
    @include pagination-container;
    text-align: center;

    li {
      vertical-align: middle;
      height: rem-calc(44);
      width: rem-calc(44);
      line-height: rem-calc(34);
      margin: rem-calc(0 3);
      padding: 0;
      font-family: $font-main;
      font-weight: 500;
    }

    a{
      display: block;
      padding: 0;
      line-height: rem-calc(44);
      transition: background .3s, color .3s;
    }

    li.pager-current{
      @include pagination-item-current;
    }

    li.pager-previous{
      @extend .pagination-previous;
    }

    li.pager-next{
      @extend .pagination-next;
    }

    li.pager-previous a,
    li.pager-next a{
      position: relative;
      font-size: 0;

      &::after{
        display: none;
      }
      &::before{
        @include glyph;
        position: absolute;
        top: 45%;
        left: 50%;
        font-size: rem-calc(20);
        margin: 0;
        transform: translate(-50%, -50%);
      }
    }
    li.pager-previous a::before{ content: "\68"; }
    li.pager-next a::before{ content: "\62"; }

    li.pager-first,
    li.pager-last{
      display: none;
    }
  }
}
