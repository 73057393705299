/**
* Masonry
* Istotope loaded via Bower
*/
.masonry{
  @extend .clearfix;
  position: relative;

  // ### Masonry Layout
  // Items Widths
  .grid-sizer,
  &__item{
    width: 48%;

    @include breakpoint(medium) {
      width: 31%; // 93% total item width
    }
    @include breakpoint(large) {
      width: 23%; // 92% total item width
    }

    // Only 3 cols on bloc multimedia or bloc with title left
    .bloc--multimedia &,
    .bloc--titleleft &,
    .bloc--related &{
      @include breakpoint(large) {
        width: 31%; // 93% total item width
      }
    }
  }

  // Items Margins
  .gutter-sizer{
    width: 4%;

    @include breakpoint(medium) {
      width: 3.5%;
    }
    @include breakpoint(large) {
      width: 2.66667%;
    }

    // Only 3 cols on bloc multimedia
    .bloc--multimedia &,
    .bloc--titleleft &,
    .bloc--related &{
      @include breakpoint(large) {
        width: 3.5%;
      }
    }
  }

  // Items Adjustements
  &__item{
    float: left; // While loading
    margin-bottom: 4%;

    @include breakpoint(medium) {
      margin-bottom: 3.5%;

      &.actualites{
        min-height: rem-calc(300);
      }

      .bloc--multimedia &{
        min-height: rem-calc(200);
      }
    }
    @include breakpoint(large) {
      margin-bottom: 2.66667%;
    }
  }
}
