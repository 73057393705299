/**
* Social Pages
* used on header and footer
*/

.social-pages {
  position: relative;

  .header__nav.mobile &{
    opacity: 0; // VelocityJS

    &::before{
      width: 84%;
      right: 50%;
      transform: translateX(50%);
    }
  }

  .header__nav &::before {
    
    @include breakpoint(xlarge) {
      content: '';
      position: absolute;
      width: rem-calc(42);
      height: rem-calc(1);
      top: 50%;
      right: 100%;
      background-color: darken($color-divider, 2%);
    }
  }
  .header__nav.mobile &::before{
    width: 84%;
    right: 50%;
    transform: translateX(50%);
  }

  /**
  * Generic Styles
  */
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    .mobile &{
      margin-top: rem-calc(40);
      justify-content: center;
    }
  }
  li{
    flex: 0 0 rem-calc(38);
    max-width: rem-calc(38);
    height: rem-calc(38);
  }
  a{
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 100%;
    position: relative;

    .header__nav &{
      background-color: #fff;
    }
  }
  [class*="icon"]{
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: rem-calc(20);
  }
  .icon-facebook{ color: #3b5998; }
  .icon-vimeo{ color: #1ab7ea; }

  /**
  * Variations
  */
  // ### Header (Desktop)
  .header__nav:not(.mobile) &{
    position: absolute;
    top: 0;
    right: 0;
    padding-left: rem-calc(10);
  }

}
