/* ==========================================================================
global.scss
========================================================================== */

body {
  font-family: $font-main;
  color: $color-dark;
}

a {
  backface-visibility: hidden;
  color: $color-main;
  transition: color .2s;

  // Link states
  [data-whatinput="mouse"] &{
    &:focus {
      outline: none;
    }
    &:hover{
      color: $color-main;
    }
  }
}

strong {
  font-weight: 700;
  color: $color-dark;
}

#skip-link {
  margin: 0;
}

h1, h2, h3, h4 {
  margin-top: 0;
  font-family: $font-main;
}

h1 {
  font-size: rem-calc(36);
  font-weight: 800;
  line-height: rem-calc(36);
}

h2 {
  margin-bottom: 0;
  font-size: rem-calc(33);
  font-weight: 700;
  line-height: rem-calc(36);
  color: $color-dark;
}

h3 {
  margin-bottom: 0;
  font-size: rem-calc(20);
  font-weight: 700;
  line-height: rem-calc(27);
}

a, input[type="submit"], input[type="button"] {

}

.highlight {
  color: $color-main;
}


/**
* Site layout
* @type {[type]}
*/
#main {
  margin-top: rem-calc(65);
  // overflow: hidden;

  @include breakpoint(large) {
    margin-top: 0;
  }
}

.page{

  &__content{

  }

  &__body{

  }
}


/* Helpers */
/*
* Hide only visually, but have it available for screenreaders: h5bp.com/v
*/
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  /*
  * Extends the .visuallyhidden class to allow the element to be focusable
  * when navigated to via the keyboard: h5bp.com/p
  */
  &--focusable {
    &:active,
    &:focus {
      clip: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
}
