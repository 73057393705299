/**
* Archives
*/

.taxonomy-term-description{
  @include flex-grid-size(11);
  margin: 0 auto;
  // font-weight: 500;
  color: rgba($color-dark, .7);
  line-height: 1.7;
  font-size: rem-calc(17);

  p{
    line-height: inherit;
  }

  @include breakpoint(large) {
    @include flex-grid-size(7);
    margin-bottom: rem-calc(80);
  }
}
