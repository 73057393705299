/**
* Lists Component
* used for agenda lists, news lists
*/

/**
* Generic Styles (agenda)
*/
.entry__item{
  @include flex-grid-row($size : #{rem-calc(1000)});

  &__link{
    @include flex-grid-column(12, 0);
    @include flex-grid-row();
    position: relative;

    // Hover animations
    [data-whatintent="mouse"] &:hover{

      .entry__item__thumb{
        transform: scale(1.25);
      }
      .entry__item__content::after{
        box-shadow: inset rem-calc(0 0 0 2) $color-divider, inset rem-calc(0 -100 0 0) $color-light, ;
      }
      .entry__item__title{
        transform: translateX(rem-calc(10));
      }
      .entry__item__localization{
        transform: translateX(rem-calc(10));
        opacity: .7;
      }
      .entry__item__dates{
        transform: translateX(rem-calc(10));
      }
      .entry__item__meta > span{
        color: $color-main;
        transform: translateX(rem-calc(-10));
      }
      .entry__item__meta .other_date{
        color: $color-dark;
        opacity: .4;
      }
      .entry__item__meta::before{
        background: $color-main;
      }
    }
  }

  &__meta{
    @include flex-grid-column(12, 0);
    font-size: rem-calc(17);
    font-weight: 500;

    .other_date{
      color: $color-dark;
      opacity: .4;
    }

    @include breakpoint(large) {
      @include flex-grid-size(2);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: right;

      // Timeline elements
      &::before,
      &::after{
        content: '';
        position: absolute;
        top: rem-calc(7);
        right: 0;
      }
      // Line
      &::after{
        width: rem-calc(8);
        height: calc(100% - #{rem-calc(22)});
        background: $color-divider;
        transform-origin: 50% 0%;
        transform: scaleY(2);
      }
      // Dots
      &::before{
        z-index: 1;
        width: rem-calc(9);
        height: rem-calc(9);
        background: $color-main;
        border-radius: 100%;
        box-shadow: rem-calc(0 0 0 3) #fff;
        transition: background .3s;
      }

      &>span{
        display: block;
        padding-right: rem-calc(20);
        transition: transform .3s .05s $easeOutQuart;
      }

      // Heure ou Jour
      .heure {
        color: $color-main;
        display: inline-block;
        transition-delay: .1s;
      }
    }
  }
  // Rounded date line on last entry
  &:last-of-type &__meta::after{
    transform: none;
    border-radius: rem-calc(8);
  }

  &__content{
    @include flex-grid-column(12, 0);
    @include flex-grid-row();
    @include flex-align(left, middle);
    align-content: center;
    flex-basis: calc(100% - #{rem-calc(55)});
    max-width: calc(100% - #{rem-calc(55)});
    margin-left: rem-calc(55);
    margin-bottom: rem-calc(15);
    min-height: rem-calc(96);
    position: relative;
    background-color: #fff;
    box-shadow: inset rem-calc(0 0 0 2) rgba($color-dark, .08);

    @include breakpoint(large) {
      margin-left: rem-calc(250);
      flex-basis: calc(100% - #{rem-calc(250)});
      max-width: calc(100% - #{rem-calc(250)});
    }

    &::after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset rem-calc(0 0 0 0) $color-divider;
      transition: box-shadow .25s $easeOutQuart;
    }
  }

  &__thumb {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    width: rem-calc(110);
    height: rem-calc(80);
    margin-top: rem-calc(-40);
    margin-left: rem-calc(-55);
    transition: transform .3s $easeOutBack;
    box-shadow: rem-calc(0 10 20) rgba($color-dark, .1);

    img {
      display: block;
    }
  }

  &__title {
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: rem-calc(10);
    margin-bottom: rem-calc(4);
    padding-left: rem-calc(70);
    padding-right: rem-calc(15);
    color: $color-dark;
    font-weight: 600;
    font-size: rem-calc(19);
    line-height: 1.1;
    transition: transform .25s .05s $easeOutQuart;

    @include breakpoint(large) {
      padding-right: rem-calc(320);
      padding-left: rem-calc(80);
      font-size: rem-calc(21);
    }
  }

  &__localization{
    position: relative;
    z-index: 1;
    width: 100%;
    line-height: 1;
    padding-left: rem-calc(70);
    margin-bottom: rem-calc(5);
    color: $color-dark;
    opacity: .4;
    font-weight: normal;
    transition: transform .25s .08s $easeOutQuart, opacity .3s;

    @include breakpoint(large) {
      margin-bottom: 0;
      padding-left: rem-calc(80);
      padding-right: rem-calc(320);
    }
  }

  &__dates{
    width: 100%;
    padding-left: rem-calc(70);
    color: $color-dark;
    font-weight: 600;
    //margin-top: rem-calc(10);
    //margin-bottom: rem-calc(10);
    position: relative;
    z-index: 1;
    transition: transform .25s .08s $easeOutQuart, opacity .3s;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;

    @include breakpoint(large) {
      height: calc(100% - #{rem-calc(15)});
      position: absolute;
      top: rem-calc(10);
      right: 0;
      width: rem-calc(300);
      padding-left: 0;
      //column-count: 2;
      //column-fill: auto;
    }

    &>div{
      @include show-for(large);
      text-transform: uppercase;
      font-size: rem-calc(14);
      opacity: .4;
      //column-span: all;
      //flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-top: rem-calc(4);
      margin-bottom: rem-calc(2);


      @include breakpoint(large) {
        width: 50%;
        max-width: 50%;
        margin-top: 0;
      }
    }
    .date{
      line-height: 1.1;
      opacity: .8;
      font-size: rem-calc(15);
      //flex: 0 0 100%;
      width: 100%;
      max-width: 100%;
      margin-bottom: rem-calc(2);

      @include breakpoint(large) {
        width: 50%;
        max-width: 50%;
        display: block;
        margin-top: rem-calc(2);

      }
    }

    .date > span{
      margin-left: rem-calc(5);
      color: scale-color($color-main, $lightness: -10%);
    }
  }

  &__jump{
    text-align: center;
    margin-top: rem-calc(20);
    position: relative;

    @include breakpoint(large) {
      margin-top: rem-calc(40);
    }

    &::after{
      content: '';
      position: absolute;
      top: 40%;
      left: 50%;
      width: 100%;
      max-width: rem-calc(1000);
      height: rem-calc(1);
      background: darken($color-divider, 2%);
      transform: translateX(-50%);
    }

    span {
      position: relative;
      z-index: 1;
      display: inline-block;
      padding: rem-calc(0 10);
      background: #fff;
      font-size: rem-calc(17);
      font-weight: 600;
    }

    a {
      position: relative;
      padding: rem-calc(4 25 2 4);
      display: inline-block;

      &::after{
        @include glyph;
        content: '\62';
        position: absolute;
        top: rem-calc(5);
        right: 0;
        font-size: rem-calc(20);
      }
    }
  }

  /**
  * Variation (News item)
  */
  &.item-news{
    border-top: rem-calc(1) solid $color-divider;
  }
  &.item-news &__content{
    margin-bottom: rem-calc(7);

    &::after{ display: none; }

    @include breakpoint(large) {
      margin-top: rem-calc(7);
      margin-bottom: rem-calc(7);
    }
  }
  &.item-news &__meta{
    margin-top: rem-calc(7);

    span{ line-height: 1; }

    &::before,
    &::after{ display: none; }

    @include breakpoint(large) {
      margin-top: rem-calc(14);
      text-align: center;

      span{
        display: block;
      }
      span.day{
        font-size: rem-calc(40);
        font-weight: normal;
        color: $color-main;
      }
      span.month{
        font-size: rem-calc(18);
        font-weight: normal;
        color: $color-dark;
        margin-top: rem-calc(3);
      }
      span.year{
        font-size: rem-calc(13);
        font-weight: normal;
        color: $color-dark;
        margin-top: rem-calc(7);
      }
    }
  }

  &.item-news &__content{

    @include breakpoint(large) {
      margin-left: rem-calc(200);
      flex-basis: calc(100% - #{rem-calc(200)});
      max-width: calc(100% - #{rem-calc(200)});
    }
  }

}
