/**
* Galerie Multimédia
*/

.node-galerie-multimedia{

  & > .field-body{
    max-width: rem-calc(1200);
    width: 91.66667%;
    margin: 0 auto;

    // font-weight: 500;
    color: rgba($color-dark, .7);
    line-height: 1.7;
    font-size: rem-calc(17);

    p{
      line-height: inherit;
    }

    @include breakpoint(large) {
      width: 58.33333%;
      margin-bottom: rem-calc(80);
    }
  }
}
