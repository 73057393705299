/**
* Page Standard
*/

.node-page{

  & > .field-body{
    max-width: rem-calc(1200);
    width: 91.66667%;
    margin: 0 auto;

    @include breakpoint(large) {
      width: 58.33333%;
      margin-bottom: rem-calc(80);
    }

    // ### Standard Styles shared with Drupal CKEditor styles
    // Styles are in `common/_mixins.scss`
    @include standard_styles;
  }
}
