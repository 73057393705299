
/**
 * Extends
 */

// Padded multiline background (https://css-tricks.com/multi-line-padded-text/)
.multiline-bg {
  padding: rem-calc(10 0);
  font-weight: 800;
  line-height: 1.4;

  @include breakpoint(medium) {
    line-height: 1.3;
  }

  p,
  span {
    display: inline-block;
    text-transform: uppercase;
    background: $color-main;
    color: #fff;
    padding: rem-calc(16 20 10);
    margin: 0;
    display: inline;
    box-decoration-break: clone;
  }
}

/**
* Mixins
*/
// Visually Hidden (as a mixin)
@mixin visuallyhidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
// Clearfix Mixin from Bootstrap 4
@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}
// Icon font
@mixin glyph {
  font-family: "sites-cultures" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Background svg mixin
// Ex: `@include background-svg('<svg xmlns="http://www.w3.or…"/><path d="M 2-…"/></svg>');
@mixin background-svg($svg){
  background-image: svg-url($svg);
  background-size: 100% 100%; // Firefox Fix
}
// Bloc penché
@mixin bloc-skew($color) {
  position: relative;
  background: linear-gradient(to bottom, rgba($color,0) 0%,rgba($color,0) 7.95vw,rgba($color,1) 7.95vw,rgba($color,1) 100%);
  margin-top: -7.95vw; // Prevent pixels bug
  overflow-x: hidden; // Prevent horizontal scrollbar in Firefox and regular mouses

  &::before{
    @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><polygon fill="#{$color}" points="0,100 100,0 100,100"/></svg>');
    content: '';
    display: block;
    width: 100vw;
    height: 8vw;
  }
}
// Text shadow
// @options:
@mixin text-shadow($color : $color-dark) {
  text-shadow: rem-calc(0 7 15) rgba($color, .1), rem-calc(0 15 30) rgba($color, .2);
}
@mixin text-shadow-dark($color : $color-dark) {
  text-shadow: rem-calc(0 7 75) rgba($color, .3), rem-calc(0 15 30) rgba($color, .7);
}
// Filet link
@mixin filet-link {
  display: inline-block;
  position: relative;
  padding-left: rem-calc(40);

  &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -.15em;
    width: rem-calc(24);
    height: rem-calc(3);
    background: $color-main;
  }
}
// Hover animation only on mouse devices (not touch)
@mixin hover_animation {
  [data-whatintent="mouse"] &{
    backface-visibility: hidden;
    will-change: transform;
    transition: transform .45s $easeOutQuart;

    &:hover{
      transform: scale(.97);
    }
  }
}

/**
* Functions
*/

@function svg-url($svg){
  //
  //  Add missing namespace
  //
  @if not str-index($svg,xmlns) {
    $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
  }
  //
  //  Chunk up string in order to avoid
  //  "stack level too deep" error
  //
  $encoded:'';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg)/$slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //
    //   Encode
    //
    $chunk: str-replace($chunk,'"', '\'');
    $chunk: str-replace($chunk,'%', '%25');
    $chunk: str-replace($chunk,'&', '%26');
    $chunk: str-replace($chunk,'#', '%23');
    $chunk: str-replace($chunk,'{', '%7B');
    $chunk: str-replace($chunk,'}', '%7D');
    $chunk: str-replace($chunk,'<', '%3C');
    $chunk: str-replace($chunk,'>', '%3E');

    //
    //    The maybe list
    //
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');

    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url("data:image/svg+xml,#{$encoded}");
}


//  Helper function to replace characters in a string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @return if($index,
  str-slice($string, 1, $index - 1) + $replace +
  str-replace(str-slice($string, $index +
  str-length($search)), $search, $replace),
  $string);
}



/**
 * Page standard standard styles
 * used in front and in CK Editor
 */
@mixin standard_styles {

  // ### Headings
  h2{
    // color: $color-main;
    // font-weight: 300;
    margin-bottom: rem-calc(30);

    @include breakpoint(large) {
      margin-left: rem-calc(50);
      margin-right: rem-calc(50);
    }
  }
  h3{
    // color: $color-alt;
    margin-bottom: rem-calc(10);

    @include breakpoint(large) {
      margin-left: rem-calc(50);
      margin-right: rem-calc(50);
    }
  }
  h4 {
    // color: $color-dark;

    @include breakpoint(large) {

    }
  }

  // ### Paragraphs
  p {
    color: rgba($color-dark, .8);
    line-height: 1.7;
    font-size: rem-calc(17);
    margin-bottom: rem-calc(30);

    @include breakpoint(large) {
      margin-left: rem-calc(50);
      margin-right: rem-calc(50);
    }
  }
  p.excerpt{
    font-weight: 400;
    font-size: rem-calc(27);
    line-height: rem-calc(23);
    line-height: 1.1;
    border-bottom: 1px solid $color-divider;
    padding-bottom: rem-calc(30);

    @include breakpoint(large) {
      font-size: rem-calc(35);
    }
  }

  // ### Quote
  p.citation,
  blockquote.citation{


    @include breakpoint(large) {

    }


  }

  // ### Lists
  ul{
    color: rgba($color-dark, .9);
    line-height: 1.7;
    font-size: rem-calc(17);
    margin-bottom: rem-calc(30);
    list-style: none;

    @include breakpoint(large) {
      margin-left: rem-calc(90);
      margin-right: rem-calc(50);
    }

    li{
      margin-bottom: rem-calc(5);

      &::before{
        @include glyph;
        margin-right: rem-calc(10);
        content: '\62';
        opacity: .5;
        position: relative;
        top: rem-calc(2);
      }
    }
  }


  // ### Images
  img.img-left,
  img.img-right{
    margin-bottom: rem-calc(20);

    // Prevent image distortion in CKEditor
    display: block;
    max-width: 100%;
    height: auto;

    @include breakpoint(large) {
      margin-top: rem-calc(8);
      max-width: rem-calc(300);
    }
  }

  p > img.img-left,
  p > img[style="float:left"] {
    margin-bottom: rem-calc(10);
    float: none !important;
    
    @include breakpoint(large) {
      margin-right: rem-calc(20);
      margin-left: rem-calc(-50);
      float: left !important;
    }
  }

  p > img.img-right,
  p > img[style="float:right"] {
    margin-bottom: rem-calc(10);
    float: none !important;
    
    @include breakpoint(large) {
      margin-left: rem-calc(20);
      margin-right: rem-calc(-50);
      float: right !important;
    }
  }

  .img-full {
    margin-bottom: rem-calc(20);

    // Prevent image distortion in CKEditor
    display: block;
    max-width: 100%;
    height: auto;

    @include breakpoint(large) {
      width: calc(100% + #{rem-calc(100)});
      max-width: calc(100% + #{rem-calc(100)});
      margin-left: rem-calc(-50);
      margin-right: rem-calc(-50);
    }
  }
}
